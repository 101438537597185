import { Mutation, Query, Role, Subscription } from 'generated/schema-types';

/**
 * RBAC permissions
 */
const permissions: Rules = {
  ADMIN: {
    static: [
      'editPaymentCreation',
      'announcementViews',
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createOneAnnouncement',
      'createOneAppointment',
      'createOnePrescription',
      'updateOnePrescription',
      'createOneBranch',
      'createOneBudget',
      'createOneCard',
      'createOneContract',
      'createOneEmployee',
      'createOneFile',
      'createOneGoal',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOneLog',
      'createOnePatient',
      'createOnePayment',
      'createOnePaymentConcept',
      'createOneRecievedAmount',
      'createOneRecurringPayment',
      'deleteOneFile',
      'deleteOnePatient',
      'chats',
      'employees',
      'evolution',
      'files',
      'globalchats',
      'globalPatients',
      'globalBudgets',
      'income',
      'invoices',
      'logs',
      'management',
      'medicalHistory',
      'patient',
      'patientChats',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateEmployeeRole',
      'updateOneAppointment',
      'updateOneBranch',
      'updateOneEmployee',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOnePayment',
      'updateOnePaymentConcept',
      'updateOneLog',
      'updateOneBudget',
      'globalIncomeReports',
      'incomeReports',
      'incomeReportsArea',
      'incomeStats',
      'incomeDailyCuts',
      'incomeReportsTables',
      'incomeMovementsReport',
      'incomePaymentsTable',
      'incomePaymentsTable',
      'incomeMorosityReport',
      'cleaningServiceReport',
      'incomeTrackingReport',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'commissionsReports',
      'archiveOnePayment',
      'archiveOneRecievedAmount',
      'createEvolutionNote',
      'deleteAnyFile',
      'cancelRecurringPayment',
      'blockPeriodsForAnyDoctor',
      'closeCashAllBranches',
      'cashClosings',
      'deleteOneAppointment'
    ]
  },
  COACH: {
    static: [
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createOneAppointment',
      'createOneLockedPeriod',
      'createOneBudget',
      'createOneCard',
      'createOnePrescription',
      'updateOnePrescription',
      'createOneGoal',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOnePatient',
      'createOneRecurringPayment',
      'deleteOnePatient',
      'employees',
      'evolution',
      'files',
      'globalchats',
      'invoices',
      'logs',
      'incomeReportsArea',
      'incomeStats',
      'incomeDailyCuts',
      'incomeReportsTables',
      'incomeMovementsReport',
      'incomePaymentsTable',
      'incomePaymentsTable',
      'incomeMorosityReport',
      'cleaningServiceReport',
      'incomeTrackingReport',
      'management',
      'medicalHistory',
      'patient',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOneBudget',
      'incomeReports',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'commissionsReports',
      'cancelRecurringPayment',
      'createLocalAppointments',
      'blockPeriodsForAnyDoctor'
    ]
  },
  COORDINATOR: {
    static: [
      'announcementViews',
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createOneAppointment',
      'createOneBudget',
      'createOneCard',
      'createOneContract',
      'createOneGoal',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOneLog',
      'createOnePatient',
      'createOneRecurringPayment',
      'deleteOnePatient',
      'chats',
      'employees',
      'evolution',
      'files',
      'globalchats',
      'invoices',
      'logs',
      'management',
      'medicalHistory',
      'patient',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateOneBranch',
      'updateOneEmployee',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOneLog',
      'updateOneBudget',
      'incomeReports',
      'incomeReportsArea',
      'incomeStats',
      'incomeDailyCuts',
      'incomeReportsTables',
      'incomeMovementsReport',
      'incomePaymentsTable',
      'incomePaymentsTable',
      'incomeMorosityReport',
      'cleaningServiceReport',
      'incomeTrackingReport',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'cancelRecurringPayment',
      'createLocalAppointments',
      'blockPeriodsForAnyDoctor'
    ]
  },
  DOCTOR: {
    static: [
      'announcementViews',
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createEvolutionNote',
      'createOneAppointment',
      'createOneLockedPeriod',
      'createOneBudget',
      'createOnePrescription',
      'updateOnePrescription',
      'createOneCard',
      'createOneContract',
      'createOneGoal',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOneLog',
      'createOnePatient',
      'createOnePayment',
      'createOneRecievedAmount',
      'createOneRecurringPayment',
      'createOneFile',
      'globalBudgets',
      'deleteOnePatient',
      'deleteOneFile',
      'chats',
      'employees',
      'evolution',
      'files',
      'invoices',
      'logs',
      'management',
      'medicalHistory',
      'patient',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOnePayment',
      'updateOneLog',
      'updateOneBudget',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'commissionsReports',
      'cancelRecurringPayment'
    ],
    dynamic: {
      updateOnePatient: ({ patientId, patientOwnerId }): boolean => {
        if (!patientId || !patientOwnerId) return false;
        return patientId === patientOwnerId;
      }
    }
  },
  DENTAL_ASSISTANT: {
    static: [
      'announcementViews',
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createOneAppointment',
      'createOneBudget',
      'createOneCard',
      'createOneContract',
      'createOneGoal',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOneLog',
      'createOnePatient',
      'createOnePayment',
      'createOneRecievedAmount',
      'createOneRecurringPayment',
      'createOneFile',
      'deleteOnePatient',
      'deleteOneFile',
      'chats',
      'employees',
      'evolution',
      'files',
      'invoices',
      'logs',
      'management',
      'medicalHistory',
      'patient',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOnePayment',
      'updateOneLog',
      'updateOneBudget',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'blockPeriodsForAnyDoctor'
    ]
  },
  RECEPTIONIST: {
    static: [
      'announcementViews',
      'appointments',
      'billing',
      'branches',
      'contracts',
      'createOneAppointment',
      'createOneBudget',
      'createOneCard',
      'createOneContract',
      'createOneInvoice',
      'createOneInvoiceFile',
      'createOneLockedPeriod',
      'createOneLog',
      'createOnePatient',
      'createOnePayment',
      'createOneRecievedAmount',
      'createOneRecurringPayment',
      'createOneFile',
      'deleteOnePatient',
      'deleteOneFile',
      'chats',
      'employees',
      'evolution',
      'files',
      'invoices',
      'logs',
      'management',
      'incomeReports',
      'incomeMorosityReport',
      'cleaningServiceReport',
      'incomeReportsArea',
      'incomeDailyCuts',
      'incomeMovementsReport',
      'cashClosings',
      'medicalHistory',
      'patient',
      'patientChats',
      'paymentConcepts',
      'recurringPayments',
      'reports',
      'updateOneInvoiceFile',
      'updateOnePatient',
      'updateOnePayment',
      'updateOneLog',
      'updateOneBudget',
      'treatmentsReports',
      'patientsReports',
      'goalsReports',
      'blockPeriodsForAnyDoctor',
      'deleteOneExpense'
    ]
  }
};

/**
 * Type defs
 */

// You can add new rules here:
export type CustomAction =
  | 'createBranchGoal'
  | 'createEvolutionNote'
  | 'updateEmployeeRole' // To limit employee edition
  | 'medicalHistory' // Medical History tab in patient
  | 'billing' // Billing tab in patient
  | 'evolution' // Evolution tab in patient
  | 'globalchats'
  | 'globalEmployees'
  | 'globalPatients'
  | 'globalBudgets'
  | 'management' // Management section
  | 'globalIncomeReports'
  | 'incomeReports'
  | 'treatmentsReports'
  | 'patientsReports'
  | 'goalsReports'
  | 'commissionsReports'
  | 'deleteAnyFile'
  | 'cancelRecurringPayment'
  | 'createLocalAppointments'
  | 'blockPeriodsForAnyDoctor'
  | 'closeCashAllBranches'
  | 'patientChats'
  | 'archiveOneRecievedAmount'
  | 'archiveOnePayment'
  | 'incomeReportsArea'
  | 'incomeStats'
  | 'incomeDailyCuts'
  | 'incomeReportsTables'
  | 'incomeMovementsReport'
  | 'incomePaymentsTable'
  | 'incomePaymentsTable'
  | 'incomeMorosityReport'
  | 'cleaningServiceReport'
  | 'incomeTrackingReport'
  | 'editPaymentCreation';

export type Action = keyof Query | keyof Mutation | keyof Subscription | CustomAction;

export type Rules = Record<Role, Rule>;

interface Rule {
  static: Action[];
  dynamic?: Partial<Record<Action, (data: Record<string, string>) => boolean>>;
  implements?: Role[];
}

export default permissions;
